import { graphql, useStaticQuery } from 'gatsby'

import { PhotosQuery } from '~/types/graphql'

const useAIDevelopmentStaticQuery = () => {
  const query = useStaticQuery<PhotosQuery>(graphql`
    query {
      clientIX: file(
        relativePath: { eq: "avatars/clients/large-photo/client-ix.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }

      fullVideoImage: file(
        relativePath: { eq: "services/video/design-video.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }
    }
  `)

  return query
}

export default useAIDevelopmentStaticQuery
