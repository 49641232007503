import { Link } from 'gatsby'

import { ServicesProcessMock } from '~/types/mock'

const AIDevProcess: ServicesProcessMock[] = [
  {
    num: '01',
    title: 'Product discovery',
    descr: (
      <>
        This crucial step helps us define your product’s vision, team
        composition, and resources. Thanks to
        <Link
          to="/services/product-discovery/"
          className="ml5"
          key="keyTitleProductDiscovery"
        >
          product discovery
        </Link>
        , we mitigate risks and plan the project delivery timeline.
      </>
    ),
  },
  {
    num: '02',
    title: 'UI/UX design',
    descr: (
      <>
        Our designers deliver
        <Link to="/services/ui-ux-design/" className="mx5" key="keyTitleDesign">
          UI/UX design services
        </Link>
        to align their views with yours and make your AI solution appealing,
        convenient, and accessible.
      </>
    ),
  },
  {
    num: '03',
    title: 'Development',
    descr:
      'We build AI solutions with enhanced productivity and personalization based on advanced algorithms and techniques.',
  },
  {
    num: '04',
    title: 'Quality assurance',
    descr: (
      <>
        With our
        <Link
          to="/services/quality-assurance/"
          className="ml5"
          key="keyTitleQA"
        >
          QA testing services
        </Link>
        , you get a stable AI solution that works correctly and as required.
      </>
    ),
  },
  {
    num: '05',
    title: 'Support and maintenance',
    descr:
      'Our team provides you with ongoing services to ensure that your AI solution stays up-to-date and aligns with your business needs.',
  },
]

export default AIDevProcess
