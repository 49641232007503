import { FAQ } from '~/types/data-array'

const AIDevFAQ: FAQ[] = [
  {
    question: 'How long does it take to create a product based on AI?',
    answer:
      'It takes from three to 36 months to develop an AI project, depending on the scope and complexity. A simple project with a minimal feature range takes around 3 to 6 months. A more complex one with advanced functionality can take around 10 to 18 months.',
  },
  {
    question: 'What does it cost to create an AI-based application?',
    answer:
      'A rough estimate for an artificial intelligence project is between $40,000 and $300,000. The price depends on many factors. The precise cost of an AI project depends on its features and timeline. Contact us to discuss the exact price for your AI project.',
  },
  {
    question: 'How will I monitor the development of my AI software project?',
    answer:
      'Our team supports clear and timely communication. We start the project with stakeholders’ discussions and maintain it throughout the development process and in support. We are attentive to your notices and feedback. So you will be on track with the project’s progress.',
  },
]

export default AIDevFAQ
