import { HomeSpecification } from '~/types/mock'

const AIDevExpertise: HomeSpecification[] = [
  {
    icon: 'sprite3DIconSmall_10',
    title: 'Online marketplaces',
    description: 'Empowered with enhanced user experiences',
    link: '/case-studies/kids-activities-service-marketplace/',
  },
  {
    icon: 'sprite3DIconSmall_13',
    title: 'eCommerce',
    description:
      'Driven by personalized experiences and immediate customer support',
    link: '/case-studies/custom-multi-vendor-marketplace-platform/',
  },
  {
    icon: 'sprite3DIconSmall_14',
    title: 'Travel',
    description: 'AI-assisted bookings and real-time support',
    link: '/case-studies/travel-management-platform/',
  },
  {
    icon: 'sprite3DIconSmall_15',
    title: 'Automotive',
    description: 'Insights into pricing and demand trends with AI',
    link: '/services/automotive-software-development/',
  },
  {
    icon: 'sprite3DIconSmall_4',
    title: 'Insurance',
    description: 'Streamlined claim processing and risk assessment',
    link: '/services/insurance-software-development/',
  },
  {
    icon: 'sprite3DIconSmall_11',
    title: 'Recruiting',
    description: 'Automated routine tasks and informed hiring decisions',
    link: '/case-studies/recruitment-progressive-web-application/',
  },
  {
    icon: 'sprite3DIconSmall_16',
    title: 'Rental',
    description:
      'AI-powered rental solutions for property sales and management',
    link: '/case-studies/accommodation-search-website-mvp/',
  },
  {
    icon: 'sprite3DIconSmall_17',
    title: 'Media',
    description: 'AI-driven tools reshape the delivery of stories',
    link: '/case-studies/news-aggregator/',
  },
  {
    icon: 'sprite3DIconSmall_18',
    title: 'Healthcare',
    description: 'Quick and reliable access to medical guidance',
    link: '/case-studies/dental-imaging-management-system/',
  },
]

export default AIDevExpertise
