import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image'

import { PhotosQuery } from '~/types/graphql'
import { cx, fileToImageLikeData } from '~/utils'

import * as containerStyles from './ServicesAIDevExpertiseSection.module.scss'

const ServicesAIDevExpertiseSection = () => {
  const query = useStaticQuery<PhotosQuery>(graphql`
    query {
      image: file(relativePath: { eq: "services/ai/ai-practices.png" }) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }
    }
  `)

  const image = getImage(fileToImageLikeData(query.image))

  return (
    <div className="container container-md">
      <div className={cx('row', containerStyles.aiDevExpertiseCases__row)}>
        <div
          className={cx(
            'col-12 col-md-6 col-lg-3',
            containerStyles.aiDevExpertiseCases__wrapperLeft,
          )}
        >
          <div className={containerStyles.aiDevExpertiseCases__card}>
            <div className={containerStyles.aiDevExpertiseCases__title}>
              Evaluating your requirements
            </div>
            <div className={containerStyles.aiDevExpertiseCases__description}>
              We prioritize your business goals and challenges. By assessing
              your needs, we know how to deliver the best AI solution for you.
            </div>
          </div>

          <div className={containerStyles.aiDevExpertiseCases__card}>
            <div className={containerStyles.aiDevExpertiseCases__title}>
              Collaboration with you
            </div>
            <div className={containerStyles.aiDevExpertiseCases__description}>
              At every step of our AI software development services, we
              prioritize communication with you. Your feedback is a valuable
              source for us to adjust as we proceed with the project.
            </div>
          </div>
        </div>

        <div
          className={cx(
            'col-6',
            containerStyles.aiDevExpertiseCases__imageDesktop,
          )}
        >
          <GatsbyImage
            image={image as IGatsbyImageData}
            alt="Our personalized approach to AI application development services| Codica"
            title="Our personalized approach to AI application development services"
          />
        </div>

        <div
          className={cx(
            'col-12 col-md-6 col-lg-3',
            containerStyles.aiDevExpertiseCases__wrapperRight,
          )}
        >
          <div className={containerStyles.aiDevExpertiseCases__card}>
            <div className={containerStyles.aiDevExpertiseCases__title}>
              Attention to project vision and timeline
            </div>
            <div className={containerStyles.aiDevExpertiseCases__description}>
              As we deliver an AI solution, we care about your unique needs. Our
              team follows the requirements to keep your app within the set time
              and budget frame.
            </div>
          </div>

          <div className={containerStyles.aiDevExpertiseCases__card}>
            <div className={containerStyles.aiDevExpertiseCases__title}>
              Suggestions based on the latest tech standards
            </div>
            <div className={containerStyles.aiDevExpertiseCases__description}>
              Our team analyses the product requirements and chooses the optimal
              techniques to deliver your AI solution. If there is an opportunity
              for improvement, we suggest it to you.
            </div>
          </div>
        </div>

        <div
          className={cx(
            'col-12 col-md-8 d-lg-none',
            containerStyles.aiDevExpertiseCases__imageMobile,
          )}
        >
          <GatsbyImage
            image={image as IGatsbyImageData}
            alt="Our personalized approach to AI application services| Codica"
            title="Our personalized approach to AI application services"
          />
        </div>
      </div>
    </div>
  )
}

export default ServicesAIDevExpertiseSection
