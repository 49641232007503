import { ExpertiseSectionMock } from '~/types/mock'

const AIDevBusiness: ExpertiseSectionMock[] = [
  {
    icon: 'spriteIcon_11',
    title: 'Low customer acquisition costs',
    description:
      'AI will analyze your customers’ preferences and behavior so you can keep them engaged and attract new ones.',
  },
  {
    icon: 'spriteIcon_50',
    title: 'Forecasting revenue and sales',
    description:
      'By identifying patterns in your historical data, AI can predict revenue outcomes for your product.',
  },
  {
    icon: 'spriteIcon_54',
    title: 'Consistent and data-driven decisions',
    description:
      'Based on rational data, AI solutions provide you with comprehensive analytics. Thus, you can make rational and productive decisions.',
  },
  {
    icon: 'spriteIcon_70',
    title: 'Better customer service',
    description:
      'With AI capabilities, you will provide a personalized experience for your customers, leading to better engagement with your app.',
  },
  {
    icon: 'spriteIcon_16',
    title: 'Saving operational costs',
    description:
      'Streamlining routine tasks with AI technologies allows you to focus on innovation and development.',
  },
]

export default AIDevBusiness
