import { ExpertiseSectionMock } from '~/types/mock'

const AIDevServices: ExpertiseSectionMock[] = [
  {
    icon: 'sprite3DIcon_4',
    title: 'Artificial Intelligence as a Service',
    description:
      'With AIaaS, you will gain scalable infrastructure even for computationally intensive solutions. At the same time, you will save time and resources for in-house development and maintenance.',
  },
  {
    icon: 'sprite3DIcon_17',
    title: 'Automation solutions',
    description:
      'As a dedicated AI software development company, we specialize in building and integrating automation tools. We use robotic process automation (RPA) and other similar technologies to streamline workflows for businesses.',
  },
  {
    icon: 'sprite3DIcon_14',
    title: 'Natural language processing',
    description:
      'Our experts know how to integrate NLP into your solution to help you process text or voice data and make sentiment analysis.',
  },
  {
    icon: 'sprite3DIcon_10',
    title: 'Expert systems',
    description:
      'These AI solutions, including recommendation engines and diagnosis tools, help businesses improve performance and user engagement.',
  },
  {
    icon: 'sprite3DIcon_12',
    title: 'AI design',
    description:
      'Our artificial intelligence development services include AI design, such as creating chatbots. By conceptualizing and planning AI systems or solutions, we make them humane and user-friendly.',
  },
  {
    icon: 'sprite3DIcon_15',
    title: 'Neural networks',
    description:
      'We implement neural networks capable of performing various tasks and giving invaluable insights. Our AI engineers will help you create a neural network and train it through deep learning.',
  },
  {
    icon: 'sprite3DIcon_19',
    title: 'AI security',
    description:
      'As we provide AI security services, our experts ensure your solutions are protected against cyber threats and vulnerabilities based on the latest cybersecurity standards.',
  },
  {
    icon: 'sprite3DIcon_16',
    title: 'AIOps',
    description:
      'By visualizing logs, metrics, and performance data, our AIOps specialists ensure continuous and optimized workflow for solutions and teams.',
  },
]

export default AIDevServices
