// extracted by mini-css-extract-plugin
export var aiAwardsSection = "V_jm";
export var aiBenefitsSection = "V_jf";
export var aiBusinessSection = "V_jj";
export var aiCasesSection = "V_jk";
export var aiCompanySection = "V_jl";
export var aiIndustriesSection = "V_jd";
export var aiPracticesSection = "V_jg";
export var aiPrimeSection = "V_jb";
export var aiProcessSection = "V_jh";
export var aiQuoteSection = "V_jn";
export var aiServicesIconSection = "V_jc";