import { ExpertiseSectionMock } from '~/types/mock'

const AIDevTrusted: ExpertiseSectionMock[] = [
  {
    icon: 'spriteIcon_22',
    title: 'Custom approach',
    description:
      'Each project for us is unique. So we take meticulous care of each of them. Knowing your needs and solution vision, we find the best ways to help you on your way to excellence.',
  },
  {
    icon: 'spriteIcon_34',
    title: 'Tangible business outcomes',
    description:
      'We help our clients get the most out of AI technologies with measurable results, such as cost savings, revenue growth, and return on investment.',
  },
  {
    icon: 'spriteIcon_47',
    title: 'Exceptional expertise',
    description:
      'Professionals in our company are well-versed in artificial intelligence app development services. From the project idea to the support, you will get top-notch results from our specialists.',
  },
  {
    icon: 'spriteIcon_60',
    title: 'Track of successful projects',
    description:
      'So far, we have delivered 50+ successful projects in various industries. Our solutions help our clients delight their customers and build trust in their brands.',
  },
  {
    icon: 'spriteIcon_63',
    title: 'Data security and compliance',
    description:
      'Following security standards and regulations is our top priority. Thanks to our experts, your AI solution or integration will be protected against vulnerabilities and cyberattacks.',
  },
  {
    icon: 'spriteIcon_98',
    title: 'Support and maintenance',
    description:
      'Our team provides continuous updates and troubleshooting to keep your solution running seamlessly. We remain dedicated to addressing your needs and maximizing your solution’s efficiency.',
  },
]

export default AIDevTrusted
