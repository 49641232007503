import { ExpertiseSectionMock } from '~/types/mock'

const AIDevBenefits: ExpertiseSectionMock[] = [
  {
    icon: 'spriteIcon_43',
    title: 'Dedicated and experienced team',
    description:
      'Over the past eight years, we have helped businesses of any scale reach their goals and even exceed their expectations.',
  },
  {
    icon: 'spriteIcon_61',
    title: 'Applying the latest standards and practices',
    description:
      'With thorough knowledge of the latest techniques and trends in software engineering, we are ready to help you overcome any challenge.',
  },
  {
    icon: 'spriteIcon_88',
    title: 'Comprehensive approach to new and existing solutions',
    description:
      'Our team delivers new projects, supports ongoing ones, and optimizes existing solutions.',
  },
  {
    icon: 'spriteIcon_15',
    title: 'Optimized development processes',
    description:
      'We follow the agile methodology as a proven method for an optimized development process with regular progress discussions.',
  },
  {
    icon: 'spriteIcon_27',
    title: 'Support for you at all steps of development',
    description:
      'Mutual understanding and regular communication bring excellent results in AI solutions development.',
  },
]

export default AIDevBenefits
